import Auth from "../components/auth";

const Login = () => {
  return (
    <Auth 
      isLogin={true}
      pageTitle={'Log In To Your Account'}
      useEmailOptionText={'Or login with your email'}
      emailSubmitButtonText={'Submit'}
    />
  )
};

export default Login;
