const SelectList = ({
  name,
  id,
  label,
  options,
  selectedOption,
  className = '',
  disabled = false,
  onChange
}: {
  name: string,
  id: string,
  label?: string,
  options: {
    [key: string]: string
  },
  selectedOption?: string,
  className?: string
  disabled?: boolean,
  onChange?: (e: any) => void
}) => {
  return (
    <div className={className}>
      {label && 
        <label htmlFor={id} className="block text-sm font-medium text-slate-700">
          {label}
        </label>
      }

      <div className="mt-1 flex rounded-md shadow-sm">
        <select
          name={name}
          id={id}
          disabled={disabled}
          autoComplete={id}
          className="block w-full rounded-md border-slate-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
          onChange={onChange ? (e) => {onChange(e)} : () => {}}
          defaultValue={selectedOption ? selectedOption : ''}
        >
          {Object.entries(options).map((item, index) => (
            <option key={`${item[0]}_${index}`} value={item[0]}>{item[1]}</option>
          ))}
        </select>
      </div>
    </div>
  )
}

export default SelectList
