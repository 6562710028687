import { useEffect, useState } from "react";
import ActionSection from "../../../ui-library/action-section";
import Alert from "../../../ui-library/alert";
import Button from "../../../ui-library/button";
import { FieldLayout, FieldsLayout, TextInput } from "../../../ui-library/fields";
import Loading from "../../../ui-library/loading";
import SectionsGroup from "../../../ui-library/sections-group";
import { get, patch, post } from "../../../utils/fetch";
import { toast } from "react-toastify";
import { redirect, useNavigate } from "react-router-dom";

const General = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [errorMessages, setErrorMessages] = useState<string[]>([]);
  const [orgId, setOrgId] = useState<string>("")
  const [orgName, setOrgName] = useState<string>("")
  const [userRole, setUserRole] = useState<string>("")
  const [orgNameError, setOrgNameError] = useState<string>("")
  const [orgNewName, setOrgNewName] = useState<string>("")
  const navigate = useNavigate();

  useEffect(() => {
    setLoading(true);
    const currentOrgId = localStorage.getItem('currentOrgId');
    if (currentOrgId) {
      setOrgId(currentOrgId);
      get(
        process.env.REACT_APP_CONSOLE_API_URL + '/user/orgs/' + currentOrgId,
        (data) => {
          setOrgName(data.user_org.OrgName)
          setUserRole(data.user_org.Role)
          // Disable loading.
          setLoading(false);
        },
        (error) => {
          if (error.error) {
            setErrorMessages([error.error])
          }
          // Disable loading.
          setLoading(false);
        },
        true
      );
    }
  }, [])

  if (loading) {
    return (
      <div className="space-y-6 sm:px-6 lg:col-span-9 lg:px-0">
        <Loading />
      </div>
    )
  }

  return (
    <>
      {errorMessages.length > 0 && errorMessages.map((msg, index) => (
        <Alert key={`alert-${index}`} type="error" message={msg} className="my-4" />
      ))}

      <SectionsGroup>
        {/* Organization ID */}
        {orgId &&
          <ActionSection
            title="Organization ID"
            description="This is your organization's ID within Dexalo."
            helpText="Used when interacting with the Dexalo API."
          >
            <FieldsLayout>
              <FieldLayout span={2}>
                <TextInput
                  id="organization_id"
                  name="organization_id"
                  defaultValue={orgId}
                  disabled={true}
                />
              </FieldLayout>
            </FieldsLayout>
          </ActionSection>
        }

        {/* Organization Name */}
        {orgName &&
          <ActionSection
            title="Organization Name"
            description="This is your organization's visible name within Dexalo. For example, the name of your company or department."
            helpText="Please use 32 characters at maximum."
            button={userRole === 'owner' ?
              <Button
                disabled={orgNewName == "" || orgNameError != "" ? true : false}
                onClick={(e) => {
                  e.preventDefault();
                  // Validate step 1 values.
                  if (orgNewName.length === 0 || orgNewName.length > 32) {
                    setOrgNameError("Invalid organization name.")
                    return
                  }

                  if (orgNewName != orgName) {
                    setLoading(true);
                    patch(
                      process.env.REACT_APP_CONSOLE_API_URL + '/user/orgs/' + orgId + '/update-name',
                      {
                        name: orgNewName
                      },
                      (data) => {
                        setOrgName(data.name);
                        setOrgNewName("");
                        // Disable loading.
                        setLoading(false);
                        // Show success message.
                        toast(data.message, {
                          type: 'success'
                        })
                      },
                      (error) => {
                        if (error.error) {
                          setErrorMessages([error.error])
                        }
                        // Disable loading.
                        setLoading(false);
                      },
                      true
                    )
                  }
                }}
              >
                Update
              </Button> : null
            }
          >
            <FieldsLayout>
              <FieldLayout span={2}>
                <TextInput
                  id="organization_name"
                  name="organization_name"
                  defaultValue={orgName}
                  onChange={(e) => {
                    if (e.target.value.length > 0 && e.target.value.length <= 32) {
                      setOrgNameError("")
                    }
                    else {
                      setOrgNameError("Invalid organization name.")
                    }
                    setOrgNewName(e.target.value)
                  }}
                  errorMessage={orgNameError}
                  disabled={userRole === 'owner' ? false : true}
                />
              </FieldLayout>
            </FieldsLayout>
          </ActionSection>
        }

        {/* Danger Zone */}
        {orgId && userRole == 'owner' &&
          <ActionSection
            title="Delete Organization"
            description="Permanently remove your Organization and all of its contents from the Dexalo platform."
            helpText="This action is not reversible — please continue with caution."
            additionalClasses="border-2 border-red-500"
            button={
              <Button
                type="danger"
                onClick={(e) => { }}
              >
                Delete
              </Button>
            }
          />
        }
      </SectionsGroup>
    </>
  )
}

export default General;
