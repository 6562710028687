const Section = ({
  title,
  description,
  additionalClasses,
  children
} : {
  title?: string
  description?: string
  additionalClasses?: string
  children?: React.ReactNode
}) => {
  return (
    <div className={`shadow sm:overflow-hidden sm:rounded-md ${additionalClasses}`}>
      <div className="bg-white py-6 px-4 sm:p-6">
        {(title || description) && 
          <div>
            { title && 
              <h2 id="payment-details-heading" className="text-lg font-medium leading-6 text-slate-900">
              {title}
              </h2>
            }
            { description && 
              <p className="mt-1 text-sm text-slate-500">
                {description}
              </p>
            }
          </div>
        }

        {children}
      </div>
    </div>
  )
}

export default Section;
