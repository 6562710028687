import { useEffect, useState } from 'react';
import Layout from "../ui-library/app-layout/layout";
import ProtectedComponent from "./protected-component";
import { useLocation, useNavigate } from 'react-router-dom'
import { useCookies } from 'react-cookie';
import {
  ArrowUturnLeftIcon,
  PlusCircleIcon
} from '@heroicons/react/24/outline';

import {
  ServerStackIcon,
  DocumentDuplicateIcon,
  HomeIcon,
  PhotoIcon,
  GlobeAltIcon,
  Cog8ToothIcon
} from '@heroicons/react/24/solid';

import { User } from '../types/types';
import Loading from '../ui-library/loading';
import { get, post } from '../utils/fetch';

const organizationNav: any = [
  { name: 'Projects', href: '/organization', icon: ServerStackIcon },
  { name: 'Settings', href: '/organization/settings', icon: Cog8ToothIcon }
]

const projectNav: any = [
  { name: 'Dashboard', href: '/', icon: HomeIcon },
  { name: 'Web Builder', href: '/web-builder', icon: GlobeAltIcon },
  { name: 'CMS', href: '/cms', icon: DocumentDuplicateIcon },
  { name: 'Media', href: '/media', icon: PhotoIcon },
  { name: 'Settings', href: '/settings', icon: Cog8ToothIcon }
]

const secondaryNavItems = [
  { name: 'Support', href: '#' },
]

const AuthenticatedLayout = ({
  children,
  scope
}: {
  children: React.ReactNode
  scope: 'setup' | 'organization' | 'project' | 'create-project'
}) => {
  const [user, setUser] = useState<User | null>(null);
  const [loading, setLoading] = useState<boolean>(false)
  const [, , removeCookie] = useCookies(['access_token']);
  const navigate = useNavigate();
  const location = useLocation();
  const [userOrgsLinks, setUserOrgsLinks] = useState<any[]>([])
  const[orgSwitcherText, setOrgSwitcherText] = useState<string>("Select Org")

  useEffect(() => {
    const localAuthUser = localStorage.getItem('authUser');
    if (localAuthUser) {
      const authUser = JSON.parse(localAuthUser);
      setUser({
        fullName: authUser.first_name + ' ' + authUser.last_name,
        initials: authUser.first_name.charAt(0).toUpperCase() + authUser.last_name.charAt(0).toUpperCase()
      })
    }
  }, [])

  useEffect(() => {
    // Local storage change event.
    // https://developer.mozilla.org/en-US/docs/Web/API/StorageEvent
    window.addEventListener("storage", (e) => {
      switch (e.key) {
        case null:
          navigate('/login');
          break;
        case 'currentOrgId':
          if (e.newValue !== e.oldValue) {
            // Redirect to org homepage.
            if (location.pathname === '/organization') {
              // if the current path is organization home refresh.
              navigate(0);
            } else {
              navigate('/organization');
            }
          }
          break;

        case 'currentProjectId':
          if (e.newValue !== e.oldValue) {
            // Redirect to project homepage.
            if (location.pathname === '/project') {
              // if the current path is home refresh.
              navigate(0);
            } else {
              navigate('/project');
            }
          }
          break;
      }
    });
  }, [navigate, location])

  useEffect(() => {
    // setLoading(true)
    get(
      process.env.REACT_APP_CONSOLE_API_URL + '/user/orgs',
      (data: any) => {
        let links: any[] = []
        data.user_orgs.forEach((element: any) => {
          links.push({
            name: element.OrgName,
            path: `/org/${element.OrgId}`
          })

          // set the switcher text of the local storage current org.
          const currentOrgId = localStorage.getItem('currentOrgId');
          if (currentOrgId && element.OrgId === currentOrgId) {
            setOrgSwitcherText(element.OrgName)
          }
        });

        setUserOrgsLinks(links)
        // setLoading(false)
      },
      (error: any) => {
        // @TODO: handle the error.
        // setLoading(false)
      },
      true
    )
  }, [])

  const signOut = () => {
    // Remove access token.
    removeCookie('access_token');
    // Remove user info from local storage.
    localStorage.removeItem('authUser')
    // Redirect to login.
    navigate('/login');
  }

  const getMainNavItems = () => {
    if (scope === 'setup' || scope === 'create-project') {
      return []
    }
    else if (scope === 'organization') {
      return organizationNav
    }
    return projectNav
  }

  const orgSmartSwitcherItems = {
    selectText: orgSwitcherText,
    topLinks: userOrgsLinks,
    topLinkAction: (e: any) => {
      e.preventDefault();
      const path = e.currentTarget.getAttribute('data-path');
      // Set the current organization to local storage.
      try {
        window.localStorage.setItem('currentOrgId', path.replace("/org/", ""))
      } catch {
        // @TODO: Catch local storage error.
      }
      // Redirect to org homepage.
      if (location.pathname === '/organization') {
        // if the current path is organization home refresh.
        navigate(0);
      } else {
        navigate('/organization');
      }
    },
    bottomLinks: [
      {
        name: 'Create Organization',
        path: '/organization/create',
        icon: PlusCircleIcon
      }
    ],
    bottomLinkAction: (e: any) => {
      e.preventDefault();
      const path = e.currentTarget.getAttribute('data-path');
      navigate(path);
    }
  }

  // temp
  const projectSmartSwitcherItems = {
    topLinks: [
      {
        name: 'Project 1',
        path: '/project/1234',
      },
      {
        name: 'Project 2',
        path: '/project/12345',
      },
      {
        name: 'Project 3',
        path: '/project/123456',
      }
    ],
    topLinkAction: (e: any) => {
      e.preventDefault();
      const path = e.currentTarget.getAttribute('data-path');
      // Set the current organization to local storage.
      try {
        window.localStorage.setItem('currentProjectId', path.replace("/project/", ""))
      } catch {
        // @TODO: Catch local storage error.
      }
      // Redirect to project homepage.
      if (location.pathname === '/project') {
        // if the current path is home refresh.
        navigate(0);
      } else {
        navigate('/project');
      }
    },
    bottomLinks: [
      {
        name: 'Org Homepage',
        path: '/organization',
        icon: ArrowUturnLeftIcon
      }
    ],
    bottomLinkAction: (e: any) => {
      e.preventDefault();
      const path = e.currentTarget.getAttribute('data-path');
      navigate(path);
    }
  }

  const getSmartSwitcher = () => {
    if (scope === 'setup') {
      return null
    }
    else if (scope === 'organization' || scope === 'create-project') {
      return orgSmartSwitcherItems
    }
    // Project scope.
    return projectSmartSwitcherItems
  }

  return (
    <ProtectedComponent>
      <>
        {!user || loading
          ? <Loading />
          : <Layout
            smartSwitcher={getSmartSwitcher()}
            mainNavItems={getMainNavItems()}
            secondaryNavItems={secondaryNavItems}
            user={user}
            signOut={signOut}
          >
            {children}
          </Layout>
        }
      </>

    </ProtectedComponent>
  )
}

export default AuthenticatedLayout;
