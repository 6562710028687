import { FieldLayout, FieldsLayout, SelectList, TextInput } from "../ui-library/fields"
import countryCodes from "../data/country-codes.json"
import { useState } from "react"

interface Address {
  addressLine1: string
  addressLine2: string
  city: string
  state: string
  postalCode: string
  country: string
}

const Address = ({
  onChange
}: {
  onChange: (address: any) => void
}) => {
  const [address, setAddress] = useState<Address>({
    addressLine1: "",
    addressLine2: "",
    city: "",
    state: "",
    postalCode: "",
    country: ""
  })

  return (
    <>
      <FieldsLayout>
        <FieldLayout span={4}>
          <TextInput
            id="addressLine1"
            name="addressLine1"
            defaultValue=""
            placeholder="Address Line 1"
            onChange={(e) => {
              address.addressLine1 = e.target.value;
              setAddress(address)
              onChange(address)
            }}
          />
        </FieldLayout>
      </FieldsLayout>

      <FieldsLayout>
        <FieldLayout span={4}>
          <TextInput
            id="addressLine2"
            name="addressLine2"
            defaultValue=""
            placeholder="Address Line 2"
            onChange={(e) => {
              address.addressLine2 = e.target.value;
              setAddress(address)
              onChange(address)
            }}
          />
        </FieldLayout>
      </FieldsLayout>

      <FieldsLayout>
        <FieldLayout span={4}>
          <TextInput
            id="city"
            name="city"
            defaultValue=""
            placeholder="City"
            onChange={(e) => {
              address.city = e.target.value;
              setAddress(address)
              onChange(address)
            }}
          />
        </FieldLayout>
      </FieldsLayout>

      <FieldsLayout>
        <FieldLayout span={2}>
          <TextInput
            id="state"
            name="state"
            defaultValue=""
            placeholder="State / Province / Region"
            onChange={(e) => {
              address.state = e.target.value;
              setAddress(address)
              onChange(address)
            }}
          />
        </FieldLayout>

        <FieldLayout span={2}>
          <TextInput
            id="postalCode"
            name="postalCode"
            defaultValue=""
            placeholder="ZIP / Postal code"
            onChange={(e) => {
              address.postalCode = e.target.value;
              setAddress(address)
              onChange(address)
            }}
          />
        </FieldLayout>
      </FieldsLayout>

      <FieldsLayout>
        <FieldLayout span={4}>
          <SelectList
            id="country"
            name="country"
            options={countryCodes}
            onChange={(e) => {
              address.country = e.target.value;
              setAddress(address)
              onChange(address)
            }}
            selectedOption="CA"
          />
        </FieldLayout>
      </FieldsLayout>
    </>
  )
}

export default Address;
