const MainContent = ({
  children
} : {
  children: React.ReactNode
}) => {
  return (
    <main className="min-w-0 flex-1 border-t border-slate-200 dark:border-slate-800 p-8 bg-slate-100 dark:bg-slate-900 overflow-auto">
      {children}
    </main>
  )
}

export default MainContent;
