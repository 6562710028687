import { Fragment } from 'react'
import { Menu, Transition } from '@headlessui/react'
import {
  BellIcon,
} from '@heroicons/react/24/outline'
import clsx from 'clsx'
import ThemeSelector from './theme-selector'
import { PrimaryPopover } from '../../popover'

const TopBarDesktop = ({
  user,
  signOut,
  navItems,
  smartSwitcher
}:{
  user: any
  signOut: () => void
  navItems?: any
  smartSwitcher?:any
}) =>{
  return (
    <div className="hidden md:flex md:min-w-0 md:flex-1 md:items-center md:justify-between">
      <div className="min-w-0 flex-1 pl-8">
        {/* Placeholder for dropdown menu or search form? */}
        {smartSwitcher && 
          <PrimaryPopover
            selectText={smartSwitcher.selectText}
            topLinks={smartSwitcher.topLinks}
            topLinkAction={smartSwitcher.topLinkAction}
            bottomLinks={smartSwitcher.bottomLinks}
            bottomLinkAction={smartSwitcher.bottomLinkAction}
          />
        }
      </div>
      <div className="ml-10 flex flex-shrink-0 items-center space-x-6 pr-6">
        <nav aria-label="Global" className="flex space-x-10">
          {navItems && navItems.map((item: any) => (
            <Fragment key={item.name}>
              <a
                href={item.href}
                className="text-sm font-medium text-slate-900 dark:text-slate-400 dark:hover:text-white"
              >
                {item.name}
              </a>
            </Fragment>
          ))}
        </nav>
        <div className="flex items-center space-x-6">
          {/* Light Dark switcher Desktop */}
          <ThemeSelector className="relative z-10 hidden md:block" />

          <span className="inline-flex">
            <a href="#" className="-mx-1 rounded-full bg-white dark:bg-slate-700 p-1 text-slate-400 dark:text-blue-400 dark:hover:text-blue-500 hover:text-slate-500">
              <span className="sr-only">View notifications</span>
              <BellIcon className="h-6 w-6" aria-hidden="true" />
            </a>
          </span>

          <Menu as="div" className="relative inline-block text-left">
            <Menu.Button className="flex rounded-full bg-white dark:bg-slate-700 text-sm focus:outline-none focus:ring-2 focus:ring-slate-600 dark:focus:ring-slate-900 focus:ring-offset-2 dark:focus:ring-offset-slate-500">
              <span className="sr-only">Open user menu</span>
              {/* <img className="h-8 w-8 rounded-full" src={user.imageUrl} alt="" /> */}
              <div className="h-10 w-10 rounded-full border-2 border-slate-200 dark:border-slate-800 dark:text-blue-400 flex items-center justify-center font-semibold p-2">{user ? user.initials: ''}</div>
            </Menu.Button>

            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white dark:bg-slate-800 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                <div className="py-1">
                  <Menu.Item>
                    {({ active }) => (
                      <a
                        href="#"
                        className={clsx(
                          active ? 'bg-slate-100 dark:bg-slate-900/40' : '',
                          'block px-4 py-2 text-sm text-slate-700 dark:text-slate-400 dark:hover:text-white'
                        )}
                      >
                        My Profile
                      </a>
                    )}
                  </Menu.Item>
                  <Menu.Item>
                    {({ active }) => (
                      <button
                        className={clsx(
                          active ? 'bg-slate-100 dark:bg-slate-900/40' : '',
                          'block px-4 py-2 text-sm text-slate-700 dark:text-slate-400 dark:hover:text-white w-full text-left'
                        )}
                        onClick={signOut}
                      >
                        Sign out
                      </button>
                    )}
                  </Menu.Item>
                </div>
              </Menu.Items>
            </Transition>
          </Menu>
        </div>
      </div>
    </div>
  )
}

export default TopBarDesktop;
