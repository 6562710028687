const FieldLayout = ({
  span,
  children
} : {
  span: 1|2|3|4|5|6
  children: React.ReactNode
}) => {
  const getClassName = () => {
    switch(span) {
      case 1:
        return 'sm:col-span-1'
      case 2:
        return 'sm:col-span-2'
      case 3:
        return 'sm:col-span-3'
      case 4:
        return 'sm:col-span-4'
      case 5:
        return 'sm:col-span-5'
      case 6:
        return 'sm:col-span-6'
    }
  }

  return (
    <div className={getClassName()}>
      {children}
    </div>
  )
}

export default FieldLayout;