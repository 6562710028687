import { RadioGroup, Switch } from '@headlessui/react'
import clsx from 'clsx'
import { useState } from 'react'

const plans = [
  { 
    name: 'Basic',
    priceMonthly: 99,
    priceYearly: 1069,
    features: ''
  },
  {
    name: 'Standard',
    priceMonthly: 449,
    priceYearly: 5388,
    features: ''
  },
  {
    name: 'Premium',
    priceMonthly: 999,
    priceYearly: 11988,
    features: ''
  }
]

// PlanId can be any of the following:
// 'basic_monthly'|'standard_monthly'|'premium_monthly'| 'basic_yearly'|'standard_yearly'|'premium_yearly'

const Plan = ({
  planId,
  onChange
}: {
  planId: string,
  onChange: (updatedPlanId: string) => void
}) => {
  const planIdSplit = planId.split('_', 2)
  let selectedPlanIndex = 0
  if (planIdSplit[0] === 'standard') {
    selectedPlanIndex = 1
  }
  else if (planIdSplit[0] === 'premium') {
    selectedPlanIndex = 2
  }

  const [selectedPlan, setSelectedPlan] = useState(plans[selectedPlanIndex])
  const [annualBillingEnabled, setAnnualBillingEnabled] = useState(planIdSplit[1] === 'yearly' ? true: false)

  const selectPlan = (plan: any) => {
    setSelectedPlan(plan)
    const updatedPlanId = plan.name.toLowerCase() + '_' + (annualBillingEnabled ? 'yearly' : 'monthly')
    onChange(updatedPlanId)
  }

  const toggleAnnualBilling = (state: boolean) => {
    setAnnualBillingEnabled(state)
    const updatedPlanId = selectedPlan.name.toLowerCase() + '_' + (state ? 'yearly' : 'monthly')
    onChange(updatedPlanId)
  }

  return (
    <>
      <RadioGroup value={selectedPlan} onChange={selectPlan} className="mt-4">
        <RadioGroup.Label className="sr-only"> Pricing plans </RadioGroup.Label>
        <div className="relative -space-y-px rounded-md bg-white dark:bg-slate-900">
          {plans.map((plan, planIdx) => (
            <RadioGroup.Option
              key={plan.name}
              value={plan}
              className={({ checked }) =>
                clsx(
                  planIdx === 0 ? 'rounded-tl-md rounded-tr-md' : '',
                  planIdx === plans.length - 1 ? 'rounded-bl-md rounded-br-md' : '',
                  checked ? 'z-10 border-blue-200 bg-blue-50 dark:bg-blue-600 dark:border-blue-800' : 'border-slate-200 dark:border-slate-700',
                  'relative flex cursor-pointer flex-col border p-4 focus:outline-none md:grid md:grid-cols-3 md:pl-4 md:pr-6'
                )
              }
            >
              {({ active, checked }) => (
                <>
                  <span className="flex items-center text-sm">
                    <span
                      className={clsx(
                        checked ? 'bg-blue-500 border-transparent' : 'bg-white dark:bg-slate-900 border-slate-300',
                        active ? 'ring-2 ring-offset-2 ring-blue-500' : '',
                        'h-4 w-4 rounded-full border flex items-center justify-center'
                      )}
                      aria-hidden="true"
                    >
                      <span className="rounded-full bg-white dark:bg-slate-900 w-1.5 h-1.5" />
                    </span>
                    <RadioGroup.Label as="span" className="ml-3 font-medium text-slate-900 dark:text-slate-50">
                      {plan.name}
                    </RadioGroup.Label>
                  </span>
                  <RadioGroup.Description
                    as="span"
                    className="ml-6 pl-1 text-sm md:ml-0 md:pl-0 md:text-center"
                  >
                    <span
                      className={clsx(
                        checked ? 'text-blue-900 dark:text-blue-100' : 'text-slate-900 dark:text-slate-50',
                        'font-medium'
                      )}
                    >
                      ${plan.priceMonthly} / mo
                    </span>{' '}
                    <span className={checked ? 'text-blue-700 dark:text-blue-100' : 'text-slate-500 dark:text-slate-300'}>
                      (${plan.priceYearly} / yr)
                    </span>
                  </RadioGroup.Description>
                  {/* <RadioGroup.Description
                    as="span"
                    className={clsx(
                      checked ? 'text-blue-700' : 'text-slate-500',
                      'ml-6 pl-1 text-sm md:ml-0 md:pl-0 md:text-right'
                    )}
                  >
                    {plan.features}
                  </RadioGroup.Description> */}
                </>
              )}
            </RadioGroup.Option>
          ))}
        </div>
      </RadioGroup>

      <Switch.Group as="div" className="flex items-center mt-4">
        <Switch
          checked={annualBillingEnabled}
          onChange={toggleAnnualBilling}
          className={clsx(
            annualBillingEnabled ? 'bg-blue-500 dark:bg-blue-400' : 'bg-slate-200 dark:bg-slate-700',
            'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-blue-500 dark:focus:ring-blue-400 focus:ring-offset-2'
          )}
        >
          <span
            aria-hidden="true"
            className={clsx(
              annualBillingEnabled ? 'translate-x-5' : 'translate-x-0',
              'inline-block h-5 w-5 transform rounded-full bg-white dark:bg-slate-900 shadow ring-0 transition duration-200 ease-in-out'
            )}
          />
        </Switch>
        <Switch.Label as="span" className="ml-3">
          <span className="text-sm font-medium text-slate-900 dark:text-slate-50">Annual billing</span>
          <span className="text-sm text-slate-500 dark:text-slate-200">(Save 10%)</span>
        </Switch.Label>
      </Switch.Group>
    </>
  )
}

export default Plan;
