import Accordion from "../../../ui-library/accordion";
import Button from "../../../ui-library/button";
import Section from "../../../ui-library/section";
import SectionsGroup from "../../../ui-library/sections-group";
import Table from "../../../ui-library/table";

interface InvoiceItem {
  summary: string,
  usage: string,
  unitPrice: string,
  amount: string
}

const Invoices = () => {
  // @todo: Replace with real data from the server.
  const invoices = [
    {
      "date": "February 2023",
      "status": "paid",
      "total": "$99",
      "items": [
        {
          "summary": "Basic Plan",
          "usage": "1",
          "unitPrice": "$0.00",
          "amount": "$0.00"
        },
        {
          "summary": "Extra Seats",
          "usage": "2",
          "unitPrice": "$20.00",
          "amount": "$40.00"
        },
        {
          "summary": "Extra project license of type X",
          "usage": "2",
          "unitPrice": "$100.00",
          "amount": "$40.00"
        },
        {
          "summary": "Extra Bandwidth",
          "usage": "2",
          "unitPrice": "$40.00/100GB",
          "amount": "$80.00"
        },
        {
          "summary": "Extra Requests",
          "usage": "5",
          "unitPrice": "$2.00/1000,000",
          "amount": "$10.00"
        }
      ] 
    }
  ];

  const invoiceTableData = (invoiceItems: InvoiceItem[], total: string) => {
    const body = [...invoiceItems, {
      "cel_1": <span className="font-bold">Total</span>,
      "cel_2":"",
      "cel_3":"",
      "cel_4": <span className="font-bold">{total}</span>
    }]

    return {
      header: ['SUMMARY', 'USAGE', 'UNIT PRICE', 'AMOUNT'],
      body: body
    }
  }

  return (
    <SectionsGroup>
      <Section
        title="Invoices"
      >
      {invoices.map((invoice: any, index: number) => (
        <div className={`${index !== 0 ? 'mt-4': ''}`}>
          <Accordion
            title={
              <div className="flex gap-4 justify-between">
                <div className="flex gap-4">
                  <span>{invoice.date}</span>
                  <span className="italic font-semibold">{invoice.status}</span>
                </div>
                <div className="font-bold">
                  {invoice.total}
                </div>
              </div>
            }
            classes="mt-4"
          >
            <>
              <div className="text-right">
                <Button 
                  type="secondary"
                  onClick={(e) => {
                    console.log('downloaded')
                  }}
                >
                  Download PDF
                </Button>
              </div>
              <Table
                data={invoiceTableData(invoice.items, invoice.total)}
                noPadding={true}
              />
            </>
            
          </Accordion>
        </div>
      ))}
      
      {/* @TODO: Pagination. */}
      </Section>
    </SectionsGroup>
  )
}

export default Invoices;
