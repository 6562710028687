import SectionsGroup from "../../../ui-library/sections-group";
import MembersInvite from "./members-invite";
import MemberList from "./members-list";

const Members = () => {
  return (
    <SectionsGroup>
      <MembersInvite />
      <MemberList />
    </SectionsGroup>
  )
}

export default Members;
