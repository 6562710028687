import { BrowserRouter, Routes, Route } from "react-router-dom";
import Login from "./pages/Login";
import Signup from "./pages/Signup";
import NoPage from "./pages/NoPage";
import AuthenticatedLayout from "./components/authenticated-layout";
import OrgHome from "./pages/organization/Home";
import OrgGeneralSettings from "./pages/organization/settings/General";
import ProjectHome from "./pages/project/Home";
import Setup from "./pages/setup/Setup";
import CreateProject from "./pages/setup/CreateProject";
import CreateOrg from "./pages/setup/CreateOrg";
import OrgSettingsLayout from "./pages/organization/settings/layout";
import General from "./pages/organization/settings/General";
import Billing from "./pages/organization/settings/Billing";
import Members from "./pages/organization/settings/Members";
import Invoices from "./pages/organization/settings/Invoices";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        {/* Public Routes */}
        <Route path="login" element={<Login />} />
        <Route path="signup" element={<Signup />} />

        {/* Protected Routes */}
        {/* Setup scope */}
        <Route path="/setup" element={
          <AuthenticatedLayout scope="setup">
            <Setup />
          </AuthenticatedLayout>}
        />
        <Route path="/organization/create" element={
          <AuthenticatedLayout scope="setup">
            <CreateOrg />
          </AuthenticatedLayout>}
        />
        <Route path="/project/create" element={
          <AuthenticatedLayout scope="create-project">
            <CreateProject />
          </AuthenticatedLayout>}
        />

        {/* Org scope */}
        <Route path="/organization" element={
          <AuthenticatedLayout scope="organization">
            <OrgHome />
          </AuthenticatedLayout>}
        />

        <Route element={
          <AuthenticatedLayout scope="organization">
            <OrgSettingsLayout />
          </AuthenticatedLayout>
        }>
          <Route path="/organization/settings" element={<General />} />
          <Route path="/organization/settings/members" element={<Members />} />
          <Route path="/organization/settings/billing" element={<Billing />} />
          <Route path="/organization/settings/invoices" element={<Invoices />} />
        </Route>

        {/* Project scope */}
        <Route path="/" element={
          <AuthenticatedLayout scope="project">
            <ProjectHome />
          </AuthenticatedLayout>}
        />

        {/* 404 */}
        <Route path="*" element={<NoPage />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
