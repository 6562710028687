const SectionsGroup = ({
  children
} : {
  children: React.ReactNode
}) => {
  return (
    <div className="space-y-6 sm:px-6 lg:col-span-9 lg:px-0">
      {children}
    </div>
  )
}

export default SectionsGroup;
