import Auth from "../components/auth";

const Signup = () => {
  return (
    <Auth 
      isLogin={false}
      pageTitle={'Create Your Account'}
      useEmailOptionText={'Or sign up with your email'}
      emailSubmitButtonText={'Sign up'}
    />
  );
};

export default Signup;
