const Loading = () =>  {
  return (
    <div className="h-full flex flex-col items-center justify-center bg-slate-100 dark:bg-slate-900">
      <div className="w-40 h-40 border-t-4 border-b-4 border-blue-900 dark:border-blue-100 rounded-full animate-spin"></div>
      <div className="flex items-center justify-center pt-4 text-blue-900 dark:text-blue-100 font-semibold">Loading...</div>
    </div>
  )
}

export default Loading;
