import {
  BanknotesIcon,
  BuildingOfficeIcon,
  CreditCardIcon,
  UsersIcon,
} from '@heroicons/react/24/outline'
import clsx from 'clsx'
import { Outlet, useLocation } from 'react-router-dom';

const navigation = [
  { name: 'General', href: '/organization/settings', icon: BuildingOfficeIcon, current: true },
  { name: 'Members', href: '/organization/settings/members', icon: UsersIcon},
  { name: 'Billing', href: '/organization/settings/billing', icon: CreditCardIcon},
  { name: 'Invoices', href: '/organization/settings/invoices', icon: BanknotesIcon},
]

const Layout = () => {
  const location = useLocation();

  const isCurrentPath = (path: string) => {
    return location.pathname === path
  }

  return (
    <>
      <h1 className="text-2xl font-bold leading-7 text-slate-700 dark:text-white sm:truncate sm:text-3xl sm:tracking-tight mb-8">Settings</h1>

      <div className="lg:grid lg:grid-cols-12 lg:gap-x-5">
        <aside className="py-6 px-2 sm:px-6 lg:col-span-3 lg:py-0 lg:px-0 lg:self-start lg:sticky lg:-top-4">
          <nav className="space-y-1">
            {navigation.map((item) => (
              <a
                key={item.name}
                href={item.href}
                className={clsx(
                  isCurrentPath(item.href)
                    ? 'bg-slate-50 dark:bg-slate-800 text-blue-600 dark:text-blue-400 hover:bg-white'
                    : 'text-slate-900 dark:text-slate-50 hover:bg-slate-50 dark:hover:bg-slate-800 hover:text-slate-900',
                  'group flex items-center rounded-md px-3 py-2 text-sm font-medium'
                )}
                aria-current={isCurrentPath(item.href) ? 'page' : undefined}
              >
                <item.icon
                  className={clsx(
                    isCurrentPath(item.href) ? 'text-blue-500 dark:text-blue-400' : 'text-slate-400 dark:text-slate-50 group-hover:text-slate-500 dark:group-hover:text-slate-50',
                    '-ml-1 mr-3 h-6 w-6 flex-shrink-0'
                  )}
                  aria-hidden="true"
                />
                <span className="truncate">{item.name}</span>
              </a>
            ))}
          </nav>
        </aside>
        
        <Outlet />
        
      </div>
    </>
  );
};

export default Layout;
