const ActionSection = ({
  title,
  description,
  helpText,
  button,
  additionalClasses,
  children
} : {
  title?: string
  description?: string
  helpText?: string
  button?: React.ReactNode
  additionalClasses?: string
  children?: React.ReactNode
}) => {

  return (
    <div className={`shadow sm:overflow-hidden sm:rounded-md ${additionalClasses}`}>
      <div className="bg-white dark:bg-slate-800 py-6 px-4 sm:p-6">
        {(title || description) && 
          <div>
            { title && 
              <h2 id="payment-details-heading" className="text-lg font-medium leading-6 text-slate-900 dark:text-white">
              {title}
              </h2>
            }
            { description && 
              <p className="mt-1 text-sm text-slate-500 dark:text-slate-200">
                {description}
              </p>
            }
          </div>
        }

        {children}
      </div>
      <div className="bg-slate-50 dark:bg-slate-800/50 px-4 py-3 sm:px-6 flex flex-col md:flex-row gap-4 justify-between items-center">
        <div>
          {helpText && 
            <p className="text-center md:text-left text-sm text-slate-500 dark:text-slate-100">{helpText}</p>
          }
        </div>
        {button}
      </div>
    </div>
  )
}

export default ActionSection;
