const Home = () => {
  const getCurrentProject = () => {
    const currentProjectId = localStorage.getItem('currentProjectId');
    return currentProjectId
  }

  return (
    <>
      <h1 className="dark:text-white text-xl">Project {getCurrentProject()}</h1>
    </>
  );
};

export default Home;
