import { useState } from "react";
import ActionSection from "../../ui-library/action-section"
import Button from "../../ui-library/button";
import { FieldLayout, FieldsLayout, TextInput } from "../../ui-library/fields"
import SectionsGroup from "../../ui-library/sections-group"
import Alert from "../../ui-library/alert";
import { Org } from "../../types/types";
import Plan from "../../components/plan";
import PaymentMethod from "../../components/payment-method";
import { getCurrentUserId } from "../../utils/user";

const CreateOrg = () => {
  const [errorMessages, setErrorMessages] = useState<string[]>([]);
  const [orgNameError, setOrgNameError] = useState<string>("")
  const [step, setStep] = useState<number>(1);
  const [org, setOrg] = useState<Org>({
    name: '',
    planId: 'basic_yearly',
    createdByUserId: getCurrentUserId()
    // address: {
    //   addressLine1: '',
    //   addressLine2: '',
    //   city: '',
    //   state: '',
    //   postalCode: '',
    //   country: ''
    // }
  })

  return (
    <div className="max-w-3xl mx-auto">
      <h1 className="text-2xl font-bold leading-7 text-slate-700 dark:text-white sm:truncate sm:text-3xl sm:tracking-tight mb-8">Create Organization</h1>
      {errorMessages.length > 0 && errorMessages.map((msg, index) => (
        <Alert key={`alert-${index}`} type="error" message={msg} className="my-4" />
      ))}

      {step === 1 &&
        <SectionsGroup>
          {/* Organization ID */}
          <ActionSection
            title="Organization Name"
            description="The name of your company or department."
            helpText="Please use 32 characters at maximum."
          >
            <FieldsLayout>
              <FieldLayout span={3}>
                <TextInput
                  id="organization_name"
                  name="organization_name"
                  defaultValue=""
                  placeholder="Organization name"
                  onChange={(e) => {
                    if (e.target.value.length > 0 && e.target.value.length <= 32) {
                      setOrgNameError("")
                    }
                    else {
                      setOrgNameError("Invalid organization name.")
                    }

                    org.name = e.target.value;
                    setOrg(org);
                  }}
                  errorMessage={orgNameError}
                />
              </FieldLayout>
            </FieldsLayout>
          </ActionSection>

          {/* Plan */}
          <ActionSection
            title="Plan"
            helpText="Switch plans or cancel any time."
          >
            <Plan
              planId={org.planId}
              onChange={(planId) => {
                org.planId = planId
                setOrg(org)
              }}
            />
          </ActionSection>

          <Button
            className="w-full justify-center"
            onClick={() => {
              // Validate step 1 values.
              if (org.name.length === 0 || org.name.length > 32) {
                setOrgNameError("Invalid organization name.")
                return
              }
              // Move to step 2.
              setStep(2)
            }}
          >
            Next
          </Button>
        </SectionsGroup>
      }

      {step === 2 &&
        <SectionsGroup>
          {/* Payment Method */}
          <PaymentMethod 
            target="createNewOrg"
            org={org}
          />

          {/* Billing Address */}
          {/* <BillingAddress
            onChange={(address) => {
              org.address = address;
              setOrg(org);
            }}
          /> */}
        </SectionsGroup>
      }
    </div>
  );
};

export default CreateOrg;
