
import { Fragment } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import {
  BellIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline'
import Logo from './logo'

const TopBarMobile = ({
  user,
  signOut,
  navItems,
  mobileMenuOpen,
  setMobileMenuOpen
} : {
  user: any
  signOut: () => void
  navItems?: any
  mobileMenuOpen: any
  setMobileMenuOpen: any
}) => {

  return (
    <Transition.Root show={mobileMenuOpen} as={Fragment}>
      <Dialog as="div" className="relative z-40 md:hidden" onClose={setMobileMenuOpen}>
        <Transition.Child
          as={Fragment}
          enter="transition-opacity ease-linear duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity ease-linear duration-300"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="hidden sm:fixed sm:inset-0 sm:block sm:bg-slate-600 sm:bg-opacity-75" />
        </Transition.Child>

        <div className="fixed inset-0 z-40">
          <Transition.Child
            as={Fragment}
            enter="transition ease-out duration-150 sm:ease-in-out sm:duration-300"
            enterFrom="transform opacity-0 scale-110 sm:translate-x-full sm:scale-100 sm:opacity-100"
            enterTo="transform opacity-100 scale-100 sm:translate-x-0 sm:scale-100 sm:opacity-100"
            leave="transition ease-in duration-150 sm:ease-in-out sm:duration-300"
            leaveFrom="transform opacity-100 scale-100 sm:translate-x-0 sm:scale-100 sm:opacity-100"
            leaveTo="transform opacity-0 scale-110 sm:translate-x-full sm:scale-100 sm:opacity-100"
          >
            <Dialog.Panel
              className="fixed inset-0 z-40 h-full w-full bg-white dark:bg-slate-800 sm:inset-y-0 sm:left-auto sm:right-0 sm:w-full sm:max-w-sm sm:shadow-lg"
              aria-label="Global"
            >
              <div className="flex h-16 items-center justify-between px-4 sm:px-6">
                <Logo type="mobile" />
                <button
                  type="button"
                  className="-mr-2 inline-flex items-center justify-center rounded-md p-2 text-slate-400 hover:bg-slate-100 hover:text-slate-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blue-600"
                  onClick={() => setMobileMenuOpen(false)}
                >
                  <span className="sr-only">Close main menu</span>
                  <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                </button>
              </div>
              <div className="max-w-8xl mx-auto mt-2 px-4 sm:px-6">
                {/* Placeholder for dropdown menu or search form? */}
              </div>
              <div className="max-w-8xl mx-auto py-3 px-2 sm:px-4">
                {navItems && navItems.map((item: any) => (
                  <Fragment key={item.name}>
                    <a
                      href={item.href}
                      className="block rounded-md py-2 px-3 text-base font-medium text-slate-900 dark:text-slate-400 hover:bg-slate-100 dark:hover:bg-slate-900 dark:hover:text-white"
                    >
                      {item.name}
                    </a>
                  </Fragment>
                ))}
              </div>
              <div className="border-t border-slate-200 pt-4 pb-3">
                <div className="max-w-8xl mx-auto flex items-center px-4 sm:px-6">
                  <div className="flex-shrink-0">
                    {/* <img className="h-10 w-10 rounded-full" src={user.imageUrl} alt="" /> */}
                    <div className="h-10 w-10 rounded-full border-2 flex items-center justify-center font-semibold p-2 dark:border-slate-600 dark:text-blue-400">{user ? user.initials: ''}</div>
                  </div>
                  <div className="ml-3 min-w-0 flex-1">
                    <div className="truncate text-base font-medium text-slate-800 dark:border-slate-800 dark:text-blue-400">{user ? user.fullName : ''}</div>
                    {/* <div className="truncate text-sm font-medium text-slate-500">{user.email}</div> */}
                  </div>
                  <a href="#" className="ml-auto flex-shrink-0 bg-white dark:bg-slate-900 p-2 text-slate-400 hover:text-slate-500">
                    <span className="sr-only">View notifications</span>
                    <BellIcon className="h-6 w-6" aria-hidden="true" />
                  </a>
                </div>
                <div className="max-w-8xl mx-auto mt-3 space-y-1 px-2 sm:px-4">
                  <a
                    href="#"
                    className="block rounded-md py-2 px-3 text-base font-medium text-slate-900 hover:bg-slate-50 dark:text-slate-400 dark:hover:text-white dark:hover:bg-slate-900"
                  >
                    My Profile
                  </a>
                  <button
                    className="block rounded-md py-2 px-3 text-base font-medium text-slate-900 hover:bg-slate-50 dark:text-slate-400 dark:hover:text-white w-full text-left dark:hover:bg-slate-900"
                    onClick={signOut}
                    >
                    Sign out
                  </button>
                </div>
              </div>
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

export default TopBarMobile;
