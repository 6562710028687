const Logo = ({
  type
} : {
  type?: 'desktop' | 'mobile'
}) => {
  const logoLink = "/logo-v2.svg"
  const logoName = "Dexalo"

  if (type === 'mobile') {
    return (
      <div>
        <img
          className="block h-8 w-auto"
          src={logoLink}
          alt={logoName}
        />
      </div>
    )
  }
  return (
    <div className="inset-y-0 left-0 md:static md:flex-shrink-0">
      <div className="flex h-16 w-16 items-center justify-center bg-blue-600 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blue-500 md:w-20">
        <img
          className="h-8 w-auto"
          src={logoLink}
          alt={logoName}
        />
      </div>
    </div>
  )
}

export default Logo;
