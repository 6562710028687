import MainMenuDesktop from './partials/main-menu-desktop'
import MainContent from './partials/main-content'
import Header from './partials/header'
import { User } from '../../types/types'
import "react-toastify/dist/ReactToastify.css"
import { ToastContainer } from "react-toastify"

const Layout = ({
  children,
  smartSwitcher,
  mainNavItems,
  secondaryNavItems,
  user,
  signOut
}: {
  children: React.ReactNode
  smartSwitcher: any
  mainNavItems: any
  secondaryNavItems: any
  user: User
  signOut: () => void
}) => {
  // Full height.
  let html = document.documentElement;
  html.className = 'h-full bg-slate-100';
  document.body.classList.add('h-full', 'overflow-hidden');
  let root = document.getElementById('root');
  root?.classList.add('h-full');

  return (
    <>
      <div className="flex h-full flex-col">
        {/* Header */}
        <Header
          smartSwitcher={smartSwitcher}
          mainNavItems={mainNavItems} // Mobile
          secondaryNavItems={secondaryNavItems}
          user={user}
          signOut={signOut}
        />

        {/* Bottom section */}
        <div className="flex min-h-0 flex-1 overflow-hidden">
          {/* Sidebar Nav */}
          { mainNavItems.length > 0 &&
            <MainMenuDesktop navItems={mainNavItems} />
          }

          {/* Main area */}
          <MainContent children={children} />
        </div>
      </div>
      <ToastContainer />
    </>
  )
}

export default Layout
