const FieldsLayout = ({
  children
} : {
  children: React.ReactNode
}) => {
  return (
    <div className="mt-4 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
      {children}
    </div>
  )
}

export default FieldsLayout;