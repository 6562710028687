
import { useState } from 'react'
import {
  Bars3Icon,
} from '@heroicons/react/24/outline'

import Logo from './logo'
import MainMenuMobile from './main-menu-mobile'
import ThemeSelector from './theme-selector'
import TopBarMobile from './top-bar-mobile'
import TopBarDesktop from './top-bar-desktop'
import { User } from '../../../types/types'

const Header = ({
  smartSwitcher,
  mainNavItems,
  secondaryNavItems,
  user,
  signOut
} : {
  smartSwitcher: any
  mainNavItems: any
  secondaryNavItems: any
  user: User
  signOut: () => void
}) => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)

  return (
    <header className="relative flex h-16 flex-shrink-0 items-center bg-white dark:bg-slate-900/95 justify-between">
      {/* Logo area */}
      <Logo />

      {/* mobile: Main menu , switcher, and toggler */}
      <div className='md:hidden relative flex basis-0 justify-end items-center gap-4 sm:gap-8 md:flex-grow'>
        {/* Main menu */}
        { mainNavItems.length > 0 &&
          <MainMenuMobile navItems={mainNavItems}/>
        }

        {/* Light Dark switcher */}
        <ThemeSelector className="relative z-10 md:hidden" />

        {/* Menu toggler */}
        <div className="relative inset-y-0 right-0 flex items-center pr-4 sm:pr-6 md:hidden">
          {/* Mobile menu button */}
          <button
            type="button"
            className="-mr-2 inline-flex items-center justify-center rounded-md p-2 text-slate-400 hover:bg-slate-100 hover:text-slate-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blue-600"
            onClick={() => setMobileMenuOpen(true)}
          >
            <span className="sr-only">Open main menu</span>
            <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
          </button>
        </div>
      </div>

      {/* Mobile menu, show/hide this `div` based on menu open/closed state */}
      <TopBarMobile
        mobileMenuOpen={mobileMenuOpen}
        setMobileMenuOpen={setMobileMenuOpen}
        navItems={secondaryNavItems}
        user={user}
        signOut={signOut}
      />

      {/* Desktop nav area */}
      <TopBarDesktop
        smartSwitcher={smartSwitcher}
        navItems={secondaryNavItems}
        user={user}
        signOut={signOut}
      />
    </header>
  )
}

export default Header
