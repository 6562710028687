import Table from "../../../ui-library/table";
import Tabs from "../../../ui-library/tabs";

const tabs = [
  'Members',
  'Pending invitations'
]

const MemberList = () => {
  const membersTableData = () => {
    // Temp content.
    // @todo: Replace with real data.
    const body: any = [
      {
        'name': "Mahmoud Zayed",
        'email': 'mahmoud@dexalo.com',
        'role': 'Owner'
      },
      {
        'name': "Mahmoud Zayed",
        'email': 'mahmoud@dexalo.com',
        'role': 'Owner'
      },
      {
        'name': "Mahmoud Zayed",
        'email': 'mahmoud@dexalo.com',
        'role': 'Owner'
      },
      {
        'name': "Mahmoud Zayed",
        'email': 'mahmoud@dexalo.com',
        'role': 'Owner'
      },
      {
        'name': "Mahmoud Zayed",
        'email': 'mahmoud@dexalo.com',
        'role': 'Owner'
      },
      {
        'name': "Mahmoud Zayed",
        'email': 'mahmoud@dexalo.com',
        'role': 'Owner'
      }
    ];

    // Add operations.
    body.map((item: any, index: number) => {
      const operations = () => (
        <div className="flex gap-4 justify-end">
          <a 
            href=""
            className="text-blue-600 hover:text-blue-900">
              Change role
          </a>
          <button 
            className="text-red-600 hover:text-red-900"
            onClick={() => {}}>
              Remove
          </button>
        </div>
      )
        

      body[index].operations = operations();
    })

    return {
      header: ['Name', 'Email', 'Role', 'Operations'],
      body: body
    }
  }

  const pendingInvitesTableData = () => {
    // Temp content.
    // @todo: Replace with real data.
    const body: any = [
      {
        'name': "Mahmoud Zayed",
        'email': 'mahmoud@dexalo.com',
        'role': 'Owner',
        'status': 'pending'
      },
      {
        'name': "Mahmoud Zayed",
        'email': 'mahmoud@dexalo.com',
        'role': 'Owner',
        'status': 'pending'
      },
      {
        'name': "Mahmoud Zayed",
        'email': 'mahmoud@dexalo.com',
        'role': 'Owner',
        'status': 'pending'
      }
    ];

    // Add operations.
    body.map((item: any, index: number) => {
      const operations = () => (
        <div className="flex gap-4 justify-end">
          <button 
            className="text-red-600 hover:text-red-900"
            onClick={() => {}}>
              Cancel invitation
          </button>
        </div>
      )
        

      body[index].operations = operations();
    })

    return {
      header: ['Name', 'Email', 'Role', 'status', 'Operations'],
      body: body
    }
  }

  return (
    <Tabs 
      tabs={tabs}
      panels={[
        <Table
          data={membersTableData()}
          hasOperations={true}
          />,
          <Table
          data={pendingInvitesTableData()}
          hasOperations={true}
          />
      ]}
    />
  )
}

export default MemberList;