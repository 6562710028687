import { 
  InformationCircleIcon,
  ExclamationTriangleIcon,
  XCircleIcon,
  CheckCircleIcon
} from '@heroicons/react/20/solid'

interface Variant {
  backgroundClass: string;
  textColor: string;
  icon: JSX.Element|null;
}

const Alert = ({type, message, className}: {type: string, message: string, className: string}) => {
  const variant = ((): Variant => {
    let backgroundClass: string = '';
    let textColor: string = '';
    let icon: JSX.Element|null = null;

    switch (type) {
      case 'info':
        backgroundClass = 'bg-blue-50'
        textColor = 'text-blue-700'
        icon = <InformationCircleIcon className="h-5 w-5 text-blue-400" aria-hidden="true" />
        break;

      case 'warning':
        backgroundClass = 'bg-yellow-50'
        textColor = 'text-yellow-700'
        icon = <ExclamationTriangleIcon className="h-5 w-5 text-yellow-400" aria-hidden="true" />
        break;

      case 'error':
        backgroundClass = 'bg-red-50'
        textColor = 'text-red-700'
        icon = <XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
        break;

      case 'success':
        backgroundClass = 'bg-green-50'
        textColor = 'text-green-700'
        icon = <CheckCircleIcon className="h-5 w-5 text-green-400" aria-hidden="true" />
        break;
    }

    return {
      backgroundClass,
      textColor,
      icon
    }
  }) ();

  return (
    <div className={`rounded-md ${variant.backgroundClass} p-4 ${className}`}>
      <div className="flex">
        <div className="flex-shrink-0">
          {variant.icon}
        </div>
        <div className="ml-3">
          <p className={`text-sm ${variant.textColor}`}>
            {message}
          </p>
        </div>
      </div>
    </div>
  )
}

export default Alert
