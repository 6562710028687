import { ChevronDownIcon } from '@heroicons/react/20/solid'

const MainMenuMobile = ({
  navItems
} : {
  navItems: any
}) => {
  return (
    <div className="mx-auto md:hidden">
      <div className="relative">
        <label htmlFor="item-select" className="sr-only">
          Choose item
        </label>
        <select
          id="item-select"
          className="rounded-md border-0 bg-none pl-3 pr-8 text-base font-medium text-slate-900 focus:ring-2 focus:ring-blue-600"
          defaultValue="" //{navItems.find((item: any) => item.current).name}
        >
          {navItems.map((item: any) => (
            <option key={item.name}>{item.name}</option>
          ))}
        </select>
        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center justify-center pr-2">
          <ChevronDownIcon className="h-5 w-5 text-slate-500" aria-hidden="true" />
        </div>
      </div>
    </div>
  )
}

export default MainMenuMobile;
