import type { TableData } from "../types/types";

const Table = ({
  data,
  hasOperations = false,
  noPadding = false
}: {
  data: TableData,
  hasOperations?: Boolean
  noPadding?: Boolean
}) => {

  return (
    <div className={`${noPadding ? '': 'px-4 sm:px-6 lg:px-8'} bg-white`}>
      {/* <div className="mt-8 flow-root"> */}
        <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
              <table className="min-w-full divide-y divide-gray-300">
                <thead>
                  <tr>
                    {(() => {
                      return data.header.map((item, index) => {
                        if (index === 0) {
                          return (
                            <th key={index + item} scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">
                              {item}
                            </th>
                          )
                        }
                        else if (hasOperations && index === data.header.length - 1) {
                          return (
                            <th key={index + item} scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                              <span className="sr-only">{item}</span>
                            </th>
                          )
                        }
                        else {
                          return (
                            <th key={index + item} scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                              {item}
                            </th>
                          )
                        }
                      })
                    }) ()}
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200">
                  {(() => {
                  return data.body.map((item, index) => {
                      const columns = Object.keys(item).map((key, index) => {
                        if (index === 0) {
                          return (
                            <td key={key + index} className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
                              {item[key]}
                            </td>
                          )
                        }
                        else if (hasOperations && index === Object.keys(item).length - 1) {
                          return (
                            <td key={key + index} className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
                              {item[key]}
                            </td>
                          )
                        }
                        else {
                          return (
                            <td key={key + index} className="whitespace-nowrap py-4 px-3 text-sm text-gray-500">{item[key]}</td>
                          )
                        }
                      })

                      return (
                        <tr key={`tr-${item.key}-${index}`}>
                            {columns}
                        </tr>
                      )
                    })

                  }) ()}
                </tbody>
              </table>
          </div>
        </div>
      {/* </div> */}
    </div>
  )
}

export default Table
