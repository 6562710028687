import { Navigate } from "react-router-dom";
import { getCookie } from "../utils/cookie";

const ProtectedComponent = ({children} : {children: JSX.Element[] | JSX.Element}) => {
  // Check if access token exist or redirect to login page.
  let authenticated = true;

  // Check access token.
  if (getCookie('access_token') === null) {
    authenticated = false;
  }

  // Check authUser on local storage.
  if (!localStorage.getItem('authUser')) {
    authenticated = false;
  }

  return (
    authenticated ? <>{children}</> : <Navigate to="/login" />
  )
}

export default ProtectedComponent;
