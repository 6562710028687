import { BuildingOffice2Icon } from '@heroicons/react/24/outline'
import { useNavigate } from 'react-router-dom'

const Projects = ({
  data
} : {
  data: any
}) => {
  const navigate = useNavigate();

  const switchProject = (pid: string) => {
    // Set the current project to local storage.
    try {
      window.localStorage.setItem('currentProjectId', pid)
    } catch {
      // @TODO: Catch local storage error.
    }
    navigate('/')
  }

  return (
    <ul className="grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
      {data.map((project: any) => (
        <li
          key={project.id}
          className="col-span-1 flex flex-col divide-y divide-slate-200 rounded-lg bg-white dark:bg-slate-800 text-center shadow dark:shadow-white/10"
          onClick={() =>{switchProject(project.id)}}
        >
          {/* <a href={`/projects/${project.id}`}> */}
            <div className="flex flex-1 flex-col p-8">
              { project.imageUrl ? 
                <img className="mx-auto h-32 w-32 flex-shrink-0 rounded-full border-2 border-slate-100 bg-slate-50" src={project.imageUrl} alt={project.name} />
              :
                <BuildingOffice2Icon className="mx-auto h-32 w-32 flex-shrink-0 text-slate-300 rounded-full border-2 border-slate-100 bg-slate-50 p-4" />
              }

              <h3 className="mt-6 text-sm font-medium text-slate-900 dark:text-slate-100">{project.name}</h3>
              <dl className="mt-1 flex flex-grow flex-col justify-between">
                <dt className="sr-only">Data Center</dt>
                <dd className="text-sm text-slate-500">{project.dataCenter}</dd>
                <dt className="sr-only">Tier</dt>
                <dd className="mt-3">
                  <span className="rounded-full bg-blue-100 px-2 py-1 text-xs font-medium text-blue-800">
                    {project.tier}
                  </span>
                </dd>
              </dl>
            </div>
          {/* </a> */}
        </li>
      ))}
    </ul>
  )
}

export default Projects
