import { ExclamationCircleIcon } from "@heroicons/react/20/solid"
import clsx from "clsx"

const TextInput = ({
  name,
  id,
  label,
  placeholder,
  defaultValue = '',
  value,
  className = '',
  disabled = false,
  errorMessage = '',
  autocomplete,
  maxLength,
  onChange
}: {
  name: string,
  id: string,
  label?: string,
  placeholder?: string,
  defaultValue?: string,
  value?: string,
  className?: string
  disabled?: boolean,
  errorMessage?: string,
  autocomplete?: string
  maxLength?: number
  onChange?: (e: any) => void
}) => {
  return (
    <div className={className}>
      {label && 
        <label htmlFor={id} className="block text-sm font-medium text-slate-700 dark:text-slate-200">
          {label}
        </label>
      }

      <div className="mt-1 flex rounded-md shadow-sm relative">
        <input
          type="text"
          name={name}
          id={id}
          className={clsx(
            errorMessage ? 'text-red-900 dark:text-red-500 placeholder-red-300 dark:placeholder-red-900 focus:border-red-500 dark:focus:border-red-400 focus:outline-none focus:ring-red-500 dark:focus:ring-red-400' :
            'border-slate-300 dark:border-slate-600 placeholder-slate-500 focus:border-blue-500 focus:ring-blue-500 dark:text-white',
            'block w-full flex-1 rounded-md dark:bg-slate-900'
          )}
          placeholder={placeholder}
          defaultValue={defaultValue}
          value={value}
          disabled={disabled}
          onChange={onChange}
          autoComplete={autocomplete}
          maxLength={maxLength}
        />

        { errorMessage &&
          <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
            <ExclamationCircleIcon className="h-5 w-5 text-red-500 dark:text-red-400" aria-hidden="true" />
          </div>
        }
      </div>

      { errorMessage &&
        <p className="mt-2 text-sm text-red-600 dark:text-red-400" id="name-error">{errorMessage}</p>
      }
    </div>
  )
}

export default TextInput
