import { Popover, Transition } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/20/solid'
import { Fragment } from 'react'

interface Button {
  name: string,
  path: string
}

interface ButtonWithIcon {
  name: string,
  path: string,
  icon: any
}

const PrimaryPopover = ({
  selectText,
  topLinks,
  topLinkAction,
  bottomLinks,
  bottomLinkAction,
} : {
  selectText: string,
  topLinks: Array<Button>,
  topLinkAction: (e: any) => void
  bottomLinks?: Array<ButtonWithIcon>
  bottomLinkAction: (e: any) => void
}) => {
  return (
    <div className="top-16 w-full max-w-sm">
      <Popover className="relative">
        {({ open }) => (
          <>
            <Popover.Button
              className={`
                ${open ? '' : 'text-opacity-90'}
                group inline-flex items-center rounded-md bg-slate-100 dark:bg-slate-700 px-3 py-2 text-base font-medium text-slate-900 dark:text-blue-400 hover:text-opacity-100 focus:outline-none focus-visible:ring-2 focus-visible:ring-white dark:focus-visible:ring-white/5 focus-visible:ring-opacity-75`}
            >
              <span>{selectText}</span>
              <ChevronDownIcon
                className={`${open ? '' : 'text-opacity-70'}
                  ml-2 h-5 w-5 text-blue-400 transition duration-150 ease-in-out group-hover:text-opacity-80`}
                aria-hidden="true"
              />
            </Popover.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute left-0 z-10 mt-3 transform px-4 sm:px-0 lg:max-w-3xl">
                <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black/5 dark:ring-white/5 ring-opacity-5">
                  <div className="relative grid gap-2 bg-white dark:bg-slate-800 p-2">
                    {topLinks.map((item, index) => (
                      <button
                        key={item.name + '-' + index}
                        data-path={item.path}
                        className="m-0 flex items-center rounded-lg p-2 transition duration-150 ease-in-out hover:bg-slate-50 dark:hover:bg-slate-900/40 focus:outline-none focus-visible:ring focus-visible:ring-blue-500 focus-visible:ring-opacity-50 text-sm font-medium text-slate-900 dark:text-slate-400 dark:hover:text-white"
                        onClick={(e) => topLinkAction(e)}
                      >
                        {item.name}
                      </button>
                    ))}
                  </div>
                  
                  {bottomLinks && bottomLinks.map((item, index) => (
                    <div key={item.name + '-' + index} className="bg-slate-50 dark:bg-slate-900 p-1">
                      <button
                        data-path={item.path}
                        className="m-0 flex gap-0.5 items-center rounded-lg p-1 transition duration-150 ease-in-out hover:bg-slate-100 dark:hover:bg-slate-700/10 focus:outline-none focus-visible:ring focus-visible:ring-blue-500 focus-visible:ring-opacity-50 text-slate-900 dark:text-slate-400 dark:hover:text-white w-full"
                        onClick={(e) => bottomLinkAction(e)}
                      >
                        <item.icon className="w-6 h-6" />
                        <p className="text-sm font-medium">{item.name}</p>
                    </button>
                  </div>
                  ))}
                  

                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    </div>
  )
}

export default PrimaryPopover;
