import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from '@stripe/stripe-js';
import { Org } from "../types/types";
import PaymentCard from "./payment-card";

const stripePromise = loadStripe('pk_test_51MjRGAJMD98DNHPHp1NiBo2fgj12F4UXHwdqvOMgczwIEufugAdorFLzUyVWtne0GsTTrjIJtex71WQuCRTFKV7V00blKEbIUt');

const PaymentMethod = ({
  target,
  org
}: {
  target: 'createNewOrg' | 'addPaymentToExistingOrg'
  org: Org
}) => {
  const options = {
    // passing the client secret obtained from the server
    // clientSecret: '{{CLIENT_SECRET}}',
  };

  return (
    <Elements stripe={stripePromise} options={options}>
      <PaymentCard target={target} org={org} />
    </Elements>
  )
}

export default PaymentMethod;
