import { getCookie } from "./cookie"

const get = (
  url: string,
  successCallback: (data: any) => void,
  errorCallback: (err: any) => void,
  authenticated?: boolean
) => {
  let requestOptions = {}
  if (authenticated) {
    requestOptions = {
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        'Authorization': `Bearer ${getCookie('access_token')}`
      }
    }
  }

  fetch(url, requestOptions)
    .then((response) => {
      if (response.ok) {
        return response.json();
      }
      else {
        return Promise.reject(response.json())
      }
    })
    .then((data) => {
      successCallback(data)
    })
    .catch(err => {
      err.then((errBody: any) => {
        errorCallback(errBody)
      })
    });
}

const post = (
  url: string,
  data: any,
  successCallback: (data: any) => void,
  errorCallback: (err: any) => void,
  authenticated?: boolean
) => {
  let requestOptions = <any> {
    method: 'POST',
    headers: <any>{
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  }

  if (authenticated) {
    requestOptions.credentials = "include";
    requestOptions.headers.Authorization = `Bearer ${getCookie('access_token')}`;
  }

  fetch(url, requestOptions)
    .then((response) => {
      if (response.ok) {
        return response.json();
      }
      else {
        return Promise.reject(response.json())
      }
    })
    .then((data) => {
      successCallback(data)
    })
    .catch(err => {
      err.then((errBody: any) => {
        errorCallback(errBody)
      })
    });
}

const patch = (
  url: string,
  data: any,
  successCallback: (data: any) => void,
  errorCallback: (err: any) => void,
  authenticated?: boolean
) => {
  let requestOptions = <any> {
    method: 'PATCH',
    headers: <any>{
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  }

  if (authenticated) {
    requestOptions.credentials = "include";
    requestOptions.headers.Authorization = `Bearer ${getCookie('access_token')}`;
  }

  fetch(url, requestOptions)
    .then((response) => {
      if (response.ok) {
        return response.json();
      }
      else {
        return Promise.reject(response.json())
      }
    })
    .then((data) => {
      successCallback(data)
    })
    .catch(err => {
      err.then((errBody: any) => {
        errorCallback(errBody)
      })
    });
}

export {
  get,
  post,
  patch
}