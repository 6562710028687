import { CardCvcElement, CardExpiryElement, CardNumberElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Org } from "../types/types";
import ActionSection from "../ui-library/action-section";
import Alert from "../ui-library/alert";
import Button from "../ui-library/button";
import { FieldLayout, FieldsLayout } from "../ui-library/fields";
import Loading from "../ui-library/loading";
import { post } from "../utils/fetch";
import { toast } from "react-toastify";

const inputWrapperClasses = "mt-1 shadow-sm border border-slate-300 dark:border-slate-600 rounded-md dark:bg-slate-900 text-blue dark:text-white px-3 py-2";

const PaymentCard = ({
  target,
  org
}: {
  target: 'createNewOrg' | 'addPaymentToExistingOrg'
  org: Org
}) => {
  const [isDark, setIsDark] = useState<Boolean>(document.documentElement.dataset.theme === "dark");
  const [errorMessages, setErrorMessages] = useState<string[]>([]);
  const observer = new MutationObserver((e) => {
    if (document.documentElement.dataset.theme === "dark") {
      setIsDark(true);
    } else {
      setIsDark(false);
    }
  });

  const HTMLElement = document.querySelector("html");

  if (HTMLElement) {
    observer.observe(HTMLElement, {
      attributes: true
    });
  }

  const inputStyle = {
    iconColor: '#c4f0ff',
    color: isDark ? '#fff' : '#000',
    fontWeight: '500',
    fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
    fontSize: '16px',
    fontSmoothing: 'antialiased',
    ':-webkit-autofill': {
      color: isDark ? '#fff' : '#000',
    },
    '::placeholder': {
      color: '#64758b',
    },
  }

  const stripe = useStripe();
  const elements = useElements();
  const [loading, setLoading] = useState<boolean>(false);
  const navigate = useNavigate();

  const createNewOrg = (orgName: string, planId: string, paymentMethodId: string, createdByUserId: string | null) => {
    setLoading(true);
    post(
      process.env.REACT_APP_CONSOLE_API_URL + '/user/orgs/create',
      {
        name: orgName,
        plan_id: planId,
        payment_method_id: paymentMethodId,
        created_by_user_id: createdByUserId
      },
      (data) => {
        // Disable loading.
        setLoading(false);
        // Set user org to the new org.
        try {
          window.localStorage.setItem('currentOrgId', data.orgId)
        } catch {
          // @TODO: Catch local storage error.
        }

        toast(`Organization has been created successfully!`, {
          type: 'success'
        })
        // Navigate to Org homepage.
        navigate('/organization')
      },
      (error) => {
        if (error.error) {
          // Disable loading.
          setLoading(false);
          setErrorMessages([error.error])
        }
      },
      true
    )
  }

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    // Get the card element.
    const card = elements.getElement(CardNumberElement);
    if (card) {
      await stripe
        .createPaymentMethod({
          type: 'card',
          card: card,
          // billing_details: {
          //   name: 'Jenny Rosen',
          // },
        })
        .then(function (result) {
          // Handle result.error or result.paymentMethod
          if (result.paymentMethod) {
            // Attach payment method to customer.
            const paymentMethodId = result.paymentMethod.id
            switch (target) {
              case "createNewOrg":
                // Create new org
                createNewOrg(org.name, org.planId, paymentMethodId, org.createdByUserId)
                break;

              case "addPaymentToExistingOrg":
                break;
            }

          }
          else if (result.error && result.error.message) {
            setErrorMessages([result.error.message])
          }
        });
    }
  }

  if (!stripe || !elements || loading) {
    // Stripe.js has not yet loaded.
    // Make sure to disable form submission until Stripe.js has loaded.
    return <Loading />
  }

  return (
    <>
      {errorMessages.length > 0 && errorMessages.map((msg, index) => (
        <Alert key={`alert-${index}`} type="error" message={msg} className="my-4" />
      ))}

      <ActionSection
        title="Payment Method"
        description="Add a payment method for your organization."
        helpText="Dexalo doesn't store the card information. We instead use Stripe to securely process transactions."
      >
        <FieldsLayout>
          <FieldLayout span={4}>
            <label htmlFor="" className="block text-sm font-medium text-slate-700 dark:text-slate-200">
              Card Number
            </label>
            <div className={inputWrapperClasses}>
              <CardNumberElement options={{
                style: {
                  base: inputStyle,
                }
              }} />
            </div>
          </FieldLayout>
        </FieldsLayout>

        <FieldsLayout>
          <FieldLayout span={2}>
            <label htmlFor="" className="block text-sm font-medium text-slate-700 dark:text-slate-200">
              Expiry
            </label>
            <div className={inputWrapperClasses}>
              <CardExpiryElement options={{
                style: {
                  base: inputStyle,
                }
              }} />
            </div>
          </FieldLayout>
          <FieldLayout span={2}>
            <label htmlFor="" className="block text-sm font-medium text-slate-700 dark:text-slate-200">
              CVC
            </label>
            <div className={inputWrapperClasses}>
              <CardCvcElement options={{
                style: {
                  base: inputStyle,
                }
              }} />
            </div>
          </FieldLayout>
        </FieldsLayout>
      </ActionSection>
      <div className="text-right">
        <Button
          className="w-full justify-center"
          onClick={handleSubmit}
        >
          Create
        </Button>
      </div>
    </>
  )
}

export default PaymentCard;
