import { useCallback, useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { useNavigate, useSearchParams } from "react-router-dom";
import validator from "validator";
import { getCookie } from "../utils/cookie";
import { loadScript } from "../utils/script";
import Loading from "../ui-library/loading";
import Alert from "../ui-library/alert";
import { post } from "../utils/fetch";

const Auth = ({
  isLogin = false,
  pageTitle,
  useEmailOptionText,
  emailSubmitButtonText
}: {
  isLogin: boolean,
  pageTitle: string,
  useEmailOptionText: string,
  emailSubmitButtonText: string
}) => {
  // Full height.
  let html = document.documentElement;
  html.className = 'h-full bg-white';
  document.body.classList.add('h-full');
  let root = document.getElementById('root');
  root?.classList.add('h-full');

  const [loading, setLoading] = useState<boolean>(false);
  const [, setCookie] = useCookies(['access_token']);
  const navigate = useNavigate();

  const [emailInput, setEmailInput] = useState<string>('');
  const [firstNameInput, setFirstNameInput] = useState<string>('');
  const [lastNameInput, setLastNameInput] = useState<string>('');
  const [errorMessages, setErrorMessages] = useState<string[]>([]);
  const [submittedSuccessfully, setSubmittedSuccessfully] = useState<boolean>(false);
  const [successMsg, setSuccessMsg] = useState<string>('');

  // Set JWT token into cookie.
  const setJWTToken = useCallback((data: any) => {
    // Set the JWT cookie
    let expires = new Date();
    expires.setTime(data.expires_at * 1000);
    setCookie('access_token', data.access_token, { path: '/',  expires});

    // Set user info into local storage.
    const authUser = {
      id: data.id,
      first_name: data.first_name,
      last_name: data.last_name
    };
    try {
      localStorage.setItem('authUser', JSON.stringify(authUser));
    }
    catch {
      // @TODO: Catch local storage error.
    }
  }, [setCookie])

  // Verify token login.
  const [searchParams, ] = useSearchParams();
  useEffect(() => {
    const verifyToken = searchParams.get('verify_token');
    if (verifyToken) {
      setLoading(true);

      post(
        process.env.REACT_APP_CONSOLE_API_URL + '/auth/email',
        {
          token: verifyToken
        },
        (data) => {
          setJWTToken(data);
          // Redirect to organization home.
          navigate('/organization');
          // Disable loading.
          setLoading(false);
        },
        (error) => {
          if (error.error) {
            setErrorMessages([error.error])
          }

          // Disable loading.
          setLoading(false);
        }
      );

      // Delete token from URL.
      searchParams.delete('verify_token');
    }
  }, [navigate, searchParams, setJWTToken])

  // Redirect to homepage if access_token exists.
  useEffect(() => {
    if (getCookie('access_token') !== null) {
      // Redirect to organization home page.
      navigate('/organization');
    } else {
      // Remove authUser from local storage.
      localStorage.removeItem('authUser');
    }
  }, [navigate])

  // Google Auth
  useEffect(() => {
    const handleGoogleCallbackResponse = (response: any) => {
      // Show loading
      setLoading(true)

      post(
        process.env.REACT_APP_CONSOLE_API_URL + '/auth/google',
        {
          credentials: response.credential
        },
        (data) => {
          setJWTToken(data);
          // Redirect to organization home.
          navigate('/organization');
          // Disable loading.
          setLoading(false);
        },
        (error) => {
          if (error.error) {
            setErrorMessages([error.error])
          }

          // Disable loading.
          setLoading(false);
        }
      );
    }

    const src = 'https://accounts.google.com/gsi/client'

    loadScript(src)
      .then(() => {
        // @ts-ignore
        google.accounts.id.initialize({
          client_id: process.env.REACT_APP_GOOGLE_IDENTITY_CLIENT_ID,
          callback: handleGoogleCallbackResponse
        });

        // @ts-ignore
        google.accounts.id.renderButton(
          document.getElementById('google-identity'),
          {
            theme: 'filled_blue',
            size: 'large',
            text: 'continue_with',
            width: '300'
          }
        )
      })
      .catch(error => {
        setErrorMessages([error])

        // Disable loading.
        setLoading(false);
      })

    return () => {
      const scriptTag = document.querySelector(`script[src="${src}"]`);
      if (scriptTag) document.body.removeChild(scriptTag);
    }
  }, [navigate, setCookie, setJWTToken]);

  const submitForm = () => {
    // Validate email.
    if (!validator.isEmail(emailInput)) {
      setErrorMessages(['Please Enter valid Email!']);
      return;
    }

    if (!isLogin) {
      // Validate sign up rest fields.
      if (firstNameInput.length < 2 || firstNameInput.length > 100) {
        setErrorMessages(['Please enter valid First name!']);
        return;
      }
  
      if (lastNameInput.length < 2 || lastNameInput.length > 100) {
        setErrorMessages(['Please enter valid Last name!']);
        return;
      }

      // Show loading.
      setLoading(true);

      // Send sign up request.
      post(
        process.env.REACT_APP_CONSOLE_API_URL + '/auth/email/sign-up',
        {
          email: emailInput,
          first_name: firstNameInput,
          last_name: lastNameInput
        },
        (data) => {
          setSubmittedSuccessfully(true);
          // Email sent to user.
          setSuccessMsg(data.message);
          // Disable loading.
          setLoading(false);
        },
        (error) => {
          if (error.error) {
            setErrorMessages([error.error])
          }
          else if (error.errors) {
            let messages = [];
            for (let i = 0; i < error.errors.length; i++) {
              let msg = '';
              const errObj = error.errors[i];
              switch(errObj.field) {
                case 'First_Name':
                  msg = `First Name: ${errObj.message}!`;
                  break;
                case 'Last_Name':
                  msg = `Last Name: ${errObj.message}!`;
                  break;
                case 'Email':
                  msg = `Email: ${errObj.message}!`;
                  break;
              }
              messages.push(msg)
            }
            setErrorMessages(messages)
          }
          // Disable loading.
          setLoading(false);
        }
      );

    }
    else {
      // Show loading.
      setLoading(true);
      // Send login request.
      post(
        process.env.REACT_APP_CONSOLE_API_URL + '/auth/email/login',
        {
          email: emailInput
        },
        (data) => {
          setSubmittedSuccessfully(true);
          // Email sent to user.
          setSuccessMsg(data.message);
          // Disable loading.
          setLoading(false);
        },
        (error) => {
          if (error.error) {
            setErrorMessages([error.error])
          }
          else if (error.errors) {
            let messages = [];
            for (let i = 0; i < error.errors.length; i++) {
              let msg = '';
              const errObj = error.errors[i];
              switch(errObj.field) {
                case 'Email':
                  msg = `Email: ${errObj.message}!`;
                  break;
              }
              messages.push(msg)
            }
            setErrorMessages(messages)
          }
          // Disable loading.
          setLoading(false);
        }
      );
    }
  }

  // Check if submitted button disabled.
  const isSubmitDisabled = () => {
    if (
      ( isLogin &&
        emailInput.length > 0
      ) || (
      !isLogin &&
      emailInput.length > 0 &&
      firstNameInput.length > 0 &&
      lastNameInput.length > 0
      )
    ) {
      return false
    }
    return true
  }

  if (loading) {
    return (
      <Loading />
    )
  }

  return (
    <>
      <div className="flex min-h-full">
        <div className="relative hidden w-0 flex-1 lg:block">
          <img
            className="absolute inset-0 h-full w-full object-cover"
            src="https://images.unsplash.com/photo-1505904267569-f02eaeb45a4c?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1908&q=80"
            alt=""
          />
        </div>
        <div className="flex flex-1 flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24 lg:w-1/2">
          <div className="mx-auto w-full max-w-md lg:w-full">
            <div className="text-center">
              <img
                className="h-12 w-auto mx-auto"
                src="/logo-dark.svg"
                alt="Dexalo"
              />
              <h2 className="text-3xl font-bold tracking-tight text-slate-900 mt-6">
                {pageTitle}
              </h2>
              <p className="mt-2 text-sm text-slate-600">
                {isLogin? 'Don\'t have an account yet?': 'Already have an account?'}{' '}
                <a href={isLogin? '/signup': '/login'} className="font-medium text-blue-600 hover:text-blue-500">
                  {isLogin? 'Sign up': 'Login'}
                </a>
              </p>

              { !successMsg && errorMessages.length > 0 && errorMessages.map((msg) => (
                <Alert type="error" message={msg} className="my-4" />
              ))}

              { successMsg &&
                <Alert type="success" message={successMsg} className="mt-4" />
              }
            </div>

            <div className="mt-6">
              <div id="google-identity" className="w-[300px] h-[44px] mx-auto"></div>
              <div>
                <div className="relative mt-6">
                  <div className="absolute inset-0 flex items-center" aria-hidden="true">
                    <div className="w-full border-t border-slate-300" />
                  </div>
                  <div className="relative flex justify-center text-sm">
                    <span className="bg-white px-2 text-slate-500">{useEmailOptionText}</span>
                  </div>
                </div>
              </div>
              <div className="mt-6">
                <div className="space-y-6">
                  <div className="space-y-1">
                    <label htmlFor="email" className="block text-sm font-medium text-slate-700">
                      Work Email *
                    </label>
                    <div className="mt-1">
                      <input
                        id="email"
                        name="email"
                        type="email"
                        autoComplete="email"
                        required
                        placeholder="name@mycompany.com"
                        className="block w-full appearance-none rounded-md border border-slate-300 px-3 py-2 placeholder-slate-400 shadow-sm focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm"
                        defaultValue={!submittedSuccessfully ? emailInput : ''}
                        onChange={(e) => setEmailInput(e.target.value)}
                      />
                    </div>
                  </div>

                  {!isLogin &&
                    <>
                      { emailInput.includes('@') &&  
                        <>
                          <div className="space-y-1">
                            <label htmlFor="first_name" className="block text-sm font-medium text-slate-700">
                              First Name *
                            </label>
                            <div className="mt-1">
                              <input
                                id="first_name"
                                name="first_name"
                                type="first_name"
                                autoComplete="first_name"
                                required
                                placeholder="First Name"
                                className="block w-full appearance-none rounded-md border border-slate-300 px-3 py-2 placeholder-slate-400 shadow-sm focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm"
                                defaultValue={!submittedSuccessfully ? firstNameInput : ''}
                                onChange={(e) => setFirstNameInput(e.target.value)}
                              />
                            </div>
                          </div>

                          <div className="space-y-1">
                            <label htmlFor="last_name" className="block text-sm font-medium text-slate-700">
                              Last Name *
                            </label>
                            <div className="mt-1">
                              <input
                                id="last_name"
                                name="last_name"
                                type="last_name"
                                autoComplete="last_name"
                                required
                                placeholder="Last Name"
                                className="block w-full appearance-none rounded-md border border-slate-300 px-3 py-2 placeholder-slate-400 shadow-sm focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm"
                                defaultValue={!submittedSuccessfully ? lastNameInput: ''}
                                onChange={(e) => setLastNameInput(e.target.value)}
                              />
                            </div>
                          </div>
                        </>
                      }

                      <p className="mt-2 text-sm text-slate-600">
                        By creating an account, I agree to Dexalo's{' '}
                        <a href="https://dexalo.com/terms-of-service" className="font-medium text-blue-600 hover:text-blue-500">
                          terms of service
                        </a>
                        {' '}and{' '}
                        <a href="https://dexalo.com/privacy-policy" className="font-medium text-blue-600 hover:text-blue-500">
                          privacy policy
                        </a>
                        .
                      </p>
                    </>
                  }

                  <div>
                    <button
                      type="submit"
                      className={`flex w-full justify-center rounded-md border border-transparent py-2 px-4 text-sm font-medium text-white shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 ${isSubmitDisabled() ? 'bg-slate-400': 'bg-blue-600 hover:bg-blue-700 focus:ring-blue-500'}`}
                      onClick={submitForm}
                      disabled={isSubmitDisabled()}
                    >
                      {emailSubmitButtonText}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        
      </div>
    </>
  );
};

export default Auth;
