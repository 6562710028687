import Address from "../../../components/address";
import ActionSection from "../../../ui-library/action-section";
import SectionsGroup from "../../../ui-library/sections-group";

const Billing = () => {
  return (
    <SectionsGroup>
      1- Current Plan, Usage of current billing cycle, and Upgrade option.
      2- Payment Method: list of card with ability to change default card.
      3- Company Name.

      <ActionSection
      title="Billing Address"
      helpText="Add address if you would like to include it to the invoice."
    >
      <Address 
        onChange={(address)=>{
          console.log(address)
        }}
      />
      </ActionSection>
      5- Tax ID?

    </SectionsGroup>
  )
}

export default Billing;
