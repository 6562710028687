import { PlusCircleIcon } from "@heroicons/react/20/solid";
import { useState } from "react";
import ActionSection from "../../../ui-library/action-section";
import Button from "../../../ui-library/button";
import { FieldLayout, FieldsLayout, SelectList, TextInput } from "../../../ui-library/fields";
import SectionsGroup from "../../../ui-library/sections-group";
import validator from "validator";

interface MemberInvite  {
  email: string
  role: "member"|"billing"|"admin"|"owner"
}

const MembersInvite = () => {
  const [invitesCount, setInvitesCounts] = useState<number>(1);
  const [invites, setInvites] = useState<MemberInvite[]>([{
    email: "",
    role: "member"
  }]);
  const [invalidEmailsIndexes, setInvalidEmailsIndexes] = useState<number[]>([]);

  const inviteMembersFields = () => {
    const fields = [];
    for (let index = 0; index < invitesCount; index++) {
      fields.push(
        <FieldsLayout key={`member-invite-${index}`}>
          <FieldLayout span={4}>
            <TextInput
              id={`email-${index}`}
              name={`email-${index}`}
              defaultValue=""
              placeholder="name@example.com"
              onChange={(e) => {
                invites[index].email = e.target.value;
                setInvites(invites);
              }}
              errorMessage={`${invalidEmailsIndexes.includes(index) ? "Invalid email." :""}`}
            />
          </FieldLayout>
          <FieldLayout span={2}>
            <SelectList
              name="role"
              id="role"
              options={{
                "member": "Member",
                "billing": "Billing",
                "admin": "Admin",
                "owner": "Owner",
              }}
              onChange={(e) => {
                invites[index].role = e.target.value;
                setInvites(invites);
              }}
            />
          </FieldLayout>
        </FieldsLayout>
      )
    }

    return (
      <>
        {fields}
        <Button
          type="secondary"
          size="xs"
          className="mt-4"
          onClick={
            (e) => {
              e.preventDefault();
              // validate previous emails.
              if (!validEmails()) return;
              // Then add more.
              setInvitesCounts(invitesCount+1)
              invites[invitesCount] = {
                email: "",
                role: "member"
              }
              setInvites(invites);
            }
          }>
            <PlusCircleIcon className="-ml-1 mr-1 h-4 w-4" aria-hidden="true" />
            Add More
        </Button>
      </>
    );
  }

  const validEmails = () => {
    let valid = true;
    setInvalidEmailsIndexes([]);
    for (let index = 0; index < invites.length; index++) {
      if (!validator.isEmail(invites[index].email)) {
        setInvalidEmailsIndexes(prev => [...prev, index]);
        valid = false;
      }
    }
    return valid;
  }

  const inviteClick = (e: any) => {
    e.preventDefault();
    // validate emails.
    if (!validEmails()) return;

    console.log(invites);
    // @todo send data to server.
  }

  return (
    <ActionSection
      title="Invite Members"
      button={
        <Button
          onClick={inviteClick}
        >
          Invite
        </Button>
      }
    >
      {inviteMembersFields()}    
    </ActionSection>
  )
}

export default MembersInvite;
