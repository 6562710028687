import clsx from "clsx";
import { Tab } from '@headlessui/react'

const Tabs = ({
  tabs,
  panels
}: {
  tabs: any
  panels: React.ReactNode[]
}) => {
  return (
    <div className="">
      <Tab.Group>
        <Tab.List className="flex space-x-1 rounded-xl bg-gradient-to-r from-blue-600 to-blue-500/80 p-1">
          {tabs.map((tab: string, index: number) => (
            <Tab
              key={index}
              className={({ selected }) =>
                clsx(
                  'w-full rounded-lg py-2.5 text-sm font-medium leading-5 text-blue-700',
                  'ring-white ring-opacity-60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2',
                  selected
                    ? 'bg-white shadow'
                    : 'text-blue-100 hover:bg-white/[0.12] hover:text-white'
                )
              }
            >
              {tab}
            </Tab>
          ))}
        </Tab.List>
        <Tab.Panels className="mt-2">
          {panels && panels.map((panel: any, index: number) => (
            <Tab.Panel
              key={index}
              className={clsx(
                'rounded-xl bg-white p-3',
                'ring-white ring-opacity-60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2'
              )}
            >
              {panel}
            </Tab.Panel>
          ))}
        </Tab.Panels>
      </Tab.Group>
    </div>
  );
};

export default Tabs;
