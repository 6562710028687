import { useNavigate } from "react-router-dom";
import Projects from "../../components/projects";
import Button from "../../ui-library/button";

const projects = [
  {
    id: '1',
    name: 'Dexalo Website',
    tier: 'Large',
    dataCenter: 'us-east-1',
    // imageUrl: '/logo-dark.svg',
    url: 'https://1234567.dexalo.app'
  },
  {
    id: '2',
    name: 'Dexalo Blog',
    tier: 'Large',
    dataCenter: 'us-east-1',
    imageUrl: '/logo-dark.svg',
  },
  {
    id: '3',
    name: 'Dexalo Blog',
    tier: 'Large',
    dataCenter: 'us-east-1',
    imageUrl: '/logo-dark.svg',
  },
  {
    id: '4',
    name: 'Dexalo Blog',
    tier: 'Large',
    dataCenter: 'us-east-1',
    imageUrl: '/logo-dark.svg',
  },
  {
    id: '5',
    name: 'Dexalo Blog',
    tier: 'Large',
    dataCenter: 'us-east-1',
    imageUrl: '/logo-dark.svg',
  },
  {
    id: '6',
    name: 'Dexalo Blog',
    tier: 'Large',
    dataCenter: 'us-east-1',
    imageUrl: '/logo-dark.svg',
  }
]

const Home = () => {
  const navigate = useNavigate();

  return (
    <>
      <div className="flex flex-col-reverse gap-y-4 lg:flex-row lg:items-center lg:justify-between">
        <h1 className="text-2xl font-bold leading-7 text-slate-700 dark:text-white sm:truncate sm:text-3xl sm:tracking-tight">Projects</h1>
        <Button
          onClick={(e) => {
            e.preventDefault();
            navigate('/project/create')
          }}
        >
          Create Project
        </Button>
      </div>

      <div className="mt-8">
        <Projects data={projects} />
      </div>
    </>
  );
};

export default Home;
