import clsx from 'clsx'
import { useLocation } from 'react-router-dom';

const MainMenuDesktop = ({
  navItems
} : {
  navItems: any
}) => {
  const location = useLocation();

  const isCurrentPath = (path: string) => {
    return location.pathname === path || location.pathname.includes(`${path}/`)
  }

  return (
    <nav aria-label="Sidebar" className="hidden md:block md:flex-shrink-0 md:overflow-y-auto md:bg-white md:dark:bg-slate-900/95">
      <div className="relative flex w-20 flex-col space-y-3 p-3 mt-3">
        {navItems.map((item: any) => (
          <a
            key={item.name}
            href={item.href}
            className={clsx(
              isCurrentPath(item.href) ? 'bg-blue-100 dark:bg-slate-700 text-blue-600 dark:text-blue-400' : 'text-slate-400 hover:bg-slate-100 dark:hover:bg-slate-900 dark:hover:text-slate-100',
              'flex-shrink-0 inline-flex items-center justify-center h-14 w-14 rounded-lg'
            )}
          >
            <span className="sr-only">{item.name}</span>
            <item.icon className="h-6 w-6" aria-hidden="true" />
          </a>
        ))}
      </div>
    </nav>
  )
}

export default MainMenuDesktop
