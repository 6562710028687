const getCurrentUserId = () => {
  let userId = null
  const localAuthUser = localStorage.getItem('authUser');
  if (localAuthUser) {
    const authUser = JSON.parse(localAuthUser);
    userId = authUser.id
  }

  return userId
}

export {
  getCurrentUserId
}
