const Button = ({
  children,
  type = 'primary',
  size = 'md',
  className = '',
  onClick,
  disabled
}: {
  children: React.ReactNode,
  type?: 'primary'|'secondary'|'danger'|'icon'
  size?: 'xs'|'sm'|'md'|'lg'|'xl'|'default',
  className?: string,
  onClick: (e: any) => void,
  disabled?: boolean
}) => {
  const typeClasses = () => {
    let classes = 'rounded-md border border-transparent font-medium shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 '
    switch (type) {
      case 'primary':
        classes += 'bg-blue-600 dark:bg-blue-500 text-white hover:bg-blue-500 dark:hover:bg-blue-400 focus:ring-blue-500 dark:focus:ring-blue-400'
        break

      case 'secondary':
        classes += 'bg-white text-slate-900 ring-1 ring-inset ring-slate-300 hover:bg-slate-50 focus:ring-slate-500'
        break
      
      case 'danger':
        classes += 'bg-red-600 dark:bg-red-500 text-white hover:bg-red-700 dark:hover:bg-red-600 focus:ring-red-500 dark:focus:ring-red-400'
        break

      case 'icon':
        classes = ''
        break
    }

    return classes
  }

  const sizeClasses = () => {
    let classes = ''
    switch (size) {
      case 'xs':
        classes = 'px-2.5 py-1.5 text-xs'
        break;

      case 'sm':
        classes = 'px-3 py-2 text-sm'
        break;

      case 'md':
        classes = 'px-4 py-2 text-sm'
        break;

      case 'lg':
        classes = 'px-4 py-2 text-base'
        break;
      
      case 'xl':
        classes = 'px-6 py-3 text-base'
        break;
    }

    return classes
  }

  return (
    <button
      type="button"
      className={`inline-flex items-center ${typeClasses()} ${sizeClasses()} ${className} ${disabled ? 'disabled:opacity-50': ''}`}
      onClick={(e) => onClick(e)}
      disabled={disabled}
    >
      {children}
    </button>
  )
}

export default Button
